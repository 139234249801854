
export const constantRoutes = [
    {
        path: '/',
        redirect: '/home',
        meta: {
            hidden: true
        }
    },
    {
        path: '/login',
        meta: {
            hidden: true
        },
        component: () => import('@/views/login/index')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            icon: 'el-icon-s-home',
            name: '首页'
        },
        component: () => import('../views/home/index.vue')
    }
]

// 任意路由
export const anyRoutes = [
    //
]

// 动态路由
export const asyncRoutes = () => [
  {
    path: '/user/list',
    name: 'UserList',
    component: () => import('@/views/user/list.vue'),
    meta: { name: '会员管理', icon: 'el-icon-user' }
  },
  {
    path: '/user/detail/:id?',
    name: 'UserDetail',
    component: () => import('@/views/user/detail.vue'),
    meta: { name: '会员详情', icon: 'el-icon-s-order', hidden: true }
  },
  {
    path: '/order/list',
    name: 'OrderList',
    component: () => import('@/views/order/list.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' }
  },
    // {
    //     path: '/orderGive/list',
    //     name: 'orderGiveList',
    //     component: () => import('@/views/orderGive/list.vue'),
    //     meta: { name: '赠送', icon: 'el-icon-s-order' }
    // }
    {
        path: '/biaoxun',
        name: 'Biaoxun',
        component: () => import('@/views/biaoxun/index.vue'),
        meta: {name: '标讯管理', icon: 'el-icon-menu'},
        children: [
            // 标讯管理
            {
                path: '/biaoxun/list',
                name: 'BiaoXunList',
                component: () => import('@/views/biaoxun/biaoxun/list.vue'),
                meta: {name: '标讯列表', icon: 'el-icon-menu'}
            },
            {
                path: '/biaoxun/add',
                name: 'BiaoXunAdd',
                component: () => import('@/views/biaoxun/biaoxun/update.vue'),
                meta: {
                    name: '新增标讯',
                    hidden: true,
                    activeMenu: '/biaoxun/list'
                }
            },
            {
                path: '/biaoxun/edit/:id?',
                name: 'BiaoXunEdit',
                component: () => import('@/views/biaoxun/biaoxun/update.vue'),
                meta: {
                    name: '编辑标讯',
                    hidden: true,
                    activeMenu: '/biaoxun/list'
                }
            },
        ]
    },
]
